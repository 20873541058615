import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="Final Disposition of Cases Filed in 2016-2019" mdxType="VizPage">
        <iframe src="https://public.tableau.com/views/FinalDispositionofCasesFiledin2016and2017/DispositionofCases?:showVizHome=no&:embed=true" allowFullScreen="true" width="715" height="835"></iframe>
        <br></br>
        <h2>{`About`}</h2>
        <br></br>
        <dt> <strong> Eviction filing: </strong> represents a court action typically filed by a landlord or property manager to begin eviction proceedings.</dt>
        <dt> <strong> Stipulated Dismissal: </strong> represents an agreement between the plantiff and defendant to agree on certain condtions to avoid an eviction being issued.</dt>
        <dt> <strong> Default Judgement: </strong> Judgements are granted when a party does not file a response or attend court.</dt>
        <dt> <strong> Dismissed: </strong> The eviction case is dismissed.</dt>
        <dt> <strong> Judgement After Court Trial: </strong> The eviction case is resolved after a court trial.</dt>
        <br></br>
        <p><strong parentName="p">{`Eviction Records`}</strong></p>
        <br></br>
        <p>{`Eviction data is pulled from the WCCA REST Interface — programmatic access to Wisconsin Circuit Court records. Small claims cases in Milwaukee County with a case type of small claims eviction are pulled down and stored on a weekly basis. CCAP provides no warranties as to the accuracy or timeliness of the information contained in the WCCA Data.`}</p>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      